import { Button, Form, message, Radio, Divider } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./deletedAudits.css";
import {
  ParseElementsIntoFormNew,
  getScore,
  getScoreQuestionCount,
  getDateAfterWeek,
} from "../../../parser";
import APICaller from "../../../utils/APICaller";
import DeletedAuditDetails from "./deletedAuditDetails";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import BeforeTableHeader from "../../layouts/beforeTableHeader";

const DeletedAuditView = ({
  children = null,
  key_elem = "1",
  nameLink = "",
}) => {
  const navigate = useNavigate();
  const [qualityScore, setQualityScore] = useState(0);
  const [auditFormAction, setAuditFormAction] = useState("");
  const location = useLocation();
  const [form] = Form.useForm();
  const [formValue, SetFormValue] = useState({});
  const [formSavingFieldType, setFormSavingFieldType] = useState("");
  const [QMF, setQMF] = useState({});
  const prevData = location?.state;

  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const maxScore = getScoreQuestionCount(QMF);
  const [scoreQuestionCount, setScoreQuestionCount] = useState(0);
  const [crrScore, setCrrScore] = useState(0);
  const [fatalNotFatal, setFatalNotFatal] = useState({});
  const [fatalCount, setFatalCount] = useState(0);
  const [notFatalCount, setNotFatalCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auditCommentMessage, setAuditCommentMessage] = useState("");

  const totalCount = QMF?.elements?.questions?.length;
  const id = location.pathname.split("/")[2];
  const [value, setValue] = useState("");
  const [aID, setAID] = useState("");
  const [tid, setTid] = useState("");
  const [commentStatus, setCommentStatus] = useState("0");
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [showDiv, setShowDiv] = useState(false);
  const [submitReview, setSubmitReview] = useState(false);
  const onClickReview = () => {
    setAuditReviewFormError({});
    setShowDiv(!showDiv);
  };
  const [showLoader, setShowLoader] = useState(true);
  const [isCritical, setIsCritical] = useState(false);
  const lIUId = secureLocalStorage.getItem("lIUId");
  /*setAuditFormInput state is used to show Perform Audit selected fields on Audit Form*/
  const [auditFormInput, setAuditFormInput] = useState({});
  const [auditReviewFormError, setAuditReviewFormError] = useState("");
  const [auditFormHasWeightage, setAuditFormHasWeightage] = useState("FALSE");
  const [dateCount, setDateCount] = useState(0);

  {
    /*Feedback*/
  }
  const [addAgentFeedback, setAddAgentFeedback] = useState(0);
  const [agentFeedback, setAgentFeedback] = useState("");
  const [addSupervisorFeedback, setAddSupervisorFeedback] = useState(0);
  const [supervisorFeedback, setSupervisorFeedback] = useState("");
  const [auditorProfileImage, setAuditorProfileImage] = useState("");
  const [agentProfileImage, setAgentProfileImage] = useState("");
  const role = secureLocalStorage.getItem("role");
  const handleCancel = () => {
    setIsModalOpen(false);
    setShowDiv(false);
  };

  const handleOk = (params) => {
    const auditId = id ? id : location.pathname.split("/")[2];
    const lIUId = secureLocalStorage.getItem("lIUId");
    const baseUrl = window.location.origin;
    const auditLink = baseUrl + `/audits/${auditId}`;

    if (params === "sd" || params === "saf") {
      APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        "/audits/submitFeedback/" + auditId,
        "PUT",
        {
          role: role,
          type: params,
          auditLink: auditLink,
          supervisorID: lIUId,
        }
      )
        .then((response) => {
          setAddSupervisorFeedback(0);
          if (response?.supervisorFeedback) {
            setSupervisorFeedback(
              JSON.parse(response?.supervisorFeedback)?.status
            );
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Supervisor Feedback": JSON.parse(response?.supervisorFeedback)
                ?.status,
            }));
          }
        })
        .catch((error) => {
          error?.data?.message && message.error(error?.data?.message);
        });
    } else if (value === "comment" && auditCommentMessage === "") {
      setAuditReviewFormError((state) => ({
        ...state,
        message: auditCommentMessage ? "" : "Comment field is required",
      }));
    } else {
      setAuditReviewFormError({});
      setSubmitReview(true);
      const dateTime = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        "/audits/submitFeedback/" + auditId,
        "PUT",
        {
          role: role,
          type: "af",
          auditLink: auditLink,
          stage:
            value === "comment"
              ? "Audit request for discussion"
              : "Audit Completed",
          comment: value === "comment" ? 1 : 0,
          message: auditCommentMessage,
          dateTime: dateTime,
          transaction_id: tid,
          agent: lIUId,
          commentStatus: value === "comment" ? "1" : "0",
        }
      )
        .then((response) => {
          if (response) {
            setShowDiv(2);
            if (response?.supervisorFeedback) {
              setSupervisorFeedback(
                JSON.parse(response?.supervisorFeedback)?.status
              );
              setAuditFormInput((prevState) => ({
                ...prevState, // Spread the previous state to retain existing key-value pairs
                "Supervisor Feedback": JSON.parse(response?.supervisorFeedback)
                  ?.status,
              }));
            }
            if (response?.agentFeedback) {
              setAgentFeedback(JSON.parse(response?.agentFeedback)?.status);
              setAuditFormInput((prevState) => ({
                ...prevState, // Spread the previous state to retain existing key-value pairs
                "Agent Feedback": JSON.parse(response?.agentFeedback)?.status,
              }));
            }
            setSubmitReview(false);
            setAddAgentFeedback(0);
          }
        })
        .catch((error) => {
          error?.data?.message && message.error(error?.data?.message);
        });
    }
  };
  // Create an object to store subarrays
  var fatalNotFatalArr = {};

  useEffect(() => {
    getAuditDetail();
  }, [location?.state?.route === "/auditForms"]);

  //begin:: show Audit Form
  const getAuditDetail = () => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      `/auditForms/updateActiveAudits/${
        secureLocalStorage.getItem("savePayloadRequestParams")
          ? secureLocalStorage.getItem("savePayloadRequestParams")?.auditForm
          : location.pathname.split("/")[2]
      }`,
      "PUT",
      {
        role: role,
        process: secureLocalStorage.getItem("savePayloadRequestParams")
          ? secureLocalStorage.getItem("savePayloadRequestParams")?.processId
          : prevData?.processId,
        activeAudits: 1,
      }
    )
      .then((response) => {})
      .catch((error) => {});
    if (secureLocalStorage.getItem("auditFormCall") === "performAuditUpdate") {
      const allKeys = Object.keys(localStorage);
      let removeItems;
      removeItems = ["@secure.n.totalAuditedCount"];
      allKeys &&
        allKeys.forEach((key) => {
          if (removeItems && removeItems.includes(key)) {
            localStorage.removeItem(key);
          }
        });
    }
    if (window.performance) {
      const entries = performance.getEntriesByType("navigation");
      if (
        secureLocalStorage.getItem("clickedLink") !== "auditsDetailLinkClick"
      ) {
        if (entries.length > 0 && entries[0].type === "reload") {
          // Page is refreshed
          secureLocalStorage.setItem("clickedLink", "auditsDetailLinkVisit");
        }
      } else {
        if (secureLocalStorage.getItem("auditFormCall") === "performAudit") {
          // Page is refreshed
          secureLocalStorage.setItem("clickedLink", "auditsDetailLinkVisit");
        } else {
        }
      }
    }
    let action;

    if (secureLocalStorage.getItem("auditFormCall") === "performAudit") {
      action = "/auditForms/";
      secureLocalStorage.removeItem("auditedQuestionCount");
      secureLocalStorage.removeItem("auditedQuestionScoreCount");
      secureLocalStorage.removeItem("timeStampsDetails");
      {
        /*Fetching Auditor Profile Image*/
      }
      if (prevData?.auditorId) {
        APICaller(
          secureLocalStorage.getItem("role"),
          secureLocalStorage.getItem("accessToken"),
          "/users/" + prevData?.auditorId,
          "GET"
        )
          .then((response) => {
            setAuditorProfileImage({
              name: response?.name,
              profilePicUrl: response?.profilePicUrl
                ? process.env.REACT_APP_BACKEND +
                  "/images/" +
                  response?.profilePicUrl +
                  ".jpg"
                : process.env.REACT_APP_BACKEND + "/images/dummy.jpg",
            });
          })
          .catch((error) => {
            error?.data?.message && message.error(error?.data?.message);
          });
      }
      if (prevData?.agentId) {
        APICaller(
          secureLocalStorage.getItem("role"),
          secureLocalStorage.getItem("accessToken"),
          "/users/" + prevData?.agentId,
          "GET"
        )
          .then((response) => {
            setAgentProfileImage({
              name: response?.name,
              profilePicUrl: response?.profilePicUrl
                ? process.env.REACT_APP_BACKEND +
                  "/images/" +
                  response?.profilePicUrl +
                  ".jpg"
                : process.env.REACT_APP_BACKEND + "/images/dummy.jpg",
            });
          })
          .catch((error) => {
            error?.data?.message && message.error(error?.data?.message);
          });
      }
    } else {
      action = "/audits/";
    }
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      `${action}${
        secureLocalStorage.getItem("savedAuditId") !== null &&
        (secureLocalStorage.getItem("auditFormCall") === null ||
          secureLocalStorage.getItem("auditFormCall") ===
            "performAuditUpdate") &&
        secureLocalStorage.getItem("fromComponent") !== "audits"
          ? secureLocalStorage.getItem("savedAuditId")
          : id
      }`,
      "GET"
    )
      .then((response) => {
        if (response?.auditor) {
          setAuditorProfileImage({
            name: response?.auditor?.name,
            profilePicUrl: response?.auditor?.profilePicUrl
              ? process.env.REACT_APP_BACKEND +
                "/images/" +
                response?.auditor?.profilePicUrl +
                ".jpg"
              : process.env.REACT_APP_BACKEND + "/images/dummy.jpg",
          });
        }
        if (response?.agent) {
          setAgentProfileImage({
            name: response?.agent?.name,
            profilePicUrl: response?.agent?.profilePicUrl
              ? process.env.REACT_APP_BACKEND +
                "/images/" +
                response?.agent?.profilePicUrl +
                ".jpg"
              : process.env.REACT_APP_BACKEND + "/images/dummy.jpg",
          });
        }

        if (response.supervisorFeedback === null) {
          setAddSupervisorFeedback(1);
        }
        if (response.agentFeedback === null) {
          setAddAgentFeedback(1);
        }
        if (response?.supervisorFeedback) {
          setSupervisorFeedback(
            JSON.parse(response?.supervisorFeedback)?.status
          );
        }
        if (response?.agentFeedback) {
          setAgentFeedback(JSON.parse(response?.agentFeedback)?.status);
        }
        if (response?.fatalNotFatalInfo) {
          setFatalCount(JSON.parse(response?.fatalNotFatalInfo)?.fatal);
          setNotFatalCount(JSON.parse(response?.fatalNotFatalInfo)?.notFatal);
        }
        if (response?.auditedScoreInfo) {
          setCrrScore(
            JSON.parse(response?.auditedScoreInfo)?.auditedQuestionCount
          );
          setScoreQuestionCount(
            JSON.parse(response?.auditedScoreInfo)?.auditedMaxQuestionCount
          );
          setQualityScore(JSON.parse(response?.auditedScoreInfo)?.score);
        }

        secureLocalStorage.removeItem("parameterSelectedList");
        let questions = JSON.parse(response?.formContent)?.elements?.questions;

        let count = 1;
        let dcount = 1;
        let totalWeightage = 0;

        // Initialize subarrays
        fatalNotFatalArr["fatal"] = [];
        fatalNotFatalArr["notFatal"] = [];

        secureLocalStorage.setItem("totalAuditedCount", 0);

        for (let i = 0; i < questions?.length; i++) {
          if (questions[i].meta.weightage) {
            totalWeightage = totalWeightage + questions[i].meta.weightage;
            setAuditFormHasWeightage("TRUE");
          }
          if (questions[i].input_type === "radio-group") {
            if (!response?.auditedScoreInfo) {
              setScoreQuestionCount(count);
            }
            secureLocalStorage.setItem("totalAuditedCount", count++);
          }

          if (questions[i].input_type === "date") {
            setDateCount(dcount++);
          }
          if (questions[i].meta?.critical === true) {
            if (questions[i].qkey) {
              fatalNotFatalArr["fatal"].push(questions[i].qkey);
            }
          } else {
            if (questions[i].qkey) {
              fatalNotFatalArr["notFatal"].push(questions[i].qkey);
            }
          }
          if (questions[i].selectedValue && questions[i].selectedValue !== "") {
            SetFormValue((state) => ({
              ...state,
              [questions[i].qkey]: [
                questions[i].selectedValue,
                questions[i].meta.isQualityMetric,
                questions[i].meta.weightage,
                totalWeightage,
              ],
            }));
          }
        }
        setFatalNotFatal(fatalNotFatalArr);

        setAuditFormInput(secureLocalStorage.getItem("auditFormInput"));
        if (secureLocalStorage.getItem("auditFormCall") !== "performAudit") {
          setAuditFormInput({
            Stage: response?.stage,
            Auditor: response?.auditor?.name,
            "Audit Score": response?.auditedScoreInfo?.score,
            Client: response?.client?.name,
            Department: response?.department?.name,
            Process: response?.process?.name,
            "Audit Form": response?.auditForm?.formName,
            Transaction: response?.transaction_id,
            Status: response?.status.toString(),
            agent:
              response?.agent?.name +
              " (Emp ID: " +
              response?.agent?.empID +
              ")",
          });

          if (response?.auditBackDateReason) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Reason for Audit's back date": response?.auditBackDateReason,
            }));
          }
          if (response?.transactionDuplicateReason) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Reason for duplicate Transaction ID":
                response?.transactionDuplicateReason,
            }));
          }
          if (response?.insuredName) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Insured Name": response?.insuredName,
            }));
          }
          if (response?.transactionAmount) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Transaction Amount": response?.transactionAmount,
            }));
          }
          if (response?.invoiceCount) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Invoice Count": response?.invoiceCount,
            }));
          }
          if (response?.agentFeedback) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Agent Feedback": JSON.parse(response?.agentFeedback)?.status,
            }));
          }
          if (response?.supervisorFeedback) {
            setAuditFormInput((prevState) => ({
              ...prevState, // Spread the previous state to retain existing key-value pairs
              "Supervisor Feedback": JSON.parse(response?.supervisorFeedback)
                ?.status,
            }));
          }
          secureLocalStorage.setItem("timeStampsDetails", {
            CreatedAt: response?.createdAt,
            UpdatedAt: response?.updatedAt,
          }); //For showing TimeStamp Details on View Audit after saving

          secureLocalStorage.setItem("savePayloadRequestParams", {
            clientId: response?.client?._id,
            departmentId: response?.department?._id,
            processId: response?.process?._id,
            transactionId: response?.transaction_id,
            transactionDate: response?.transactionDate,
            agentId: response?.agent?._id,
            formContent: response?.formContent,
            Stage: response?.stage.toString(),
            auditForm: response?.auditForm?._id,
            score: response?.auditedScoreInfo?.score,
            auditedQuestionCount: response?.auditedQuestionCount,
            transactionDuplicateReason: response?.transactionDuplicateReason,
            auditBackDateReason: response?.auditBackDateReason,
            insuredName: response?.insuredName,
            transactionAmount: response?.transactionAmount,
            invoiceCount: response?.invoiceCount,
            aditorID: response?.auditor?._id,
          }); //For sending audit details for auto save on change
          secureLocalStorage.setItem("aID", response?.agent?._id);

          setAID(response?.agent?._id);
          setTid(response?.transaction_id);
          setCommentStatus(response?.commentStatus);
          secureLocalStorage.setItem(
            "auditScore",
            secureLocalStorage.getItem("savePayloadRequestParams")?.score
          );
          secureLocalStorage.setItem(
            "auditedQuestionCount",
            secureLocalStorage.getItem("savePayloadRequestParams")
              ?.auditedQuestionCount
          );

          secureLocalStorage.setItem("auditedFormSubmit", false);
          if (response?.stage.toString() === "Audit Completed") {
            secureLocalStorage.setItem("auditedFormSubmit", true);
          }
        }

        if (id && secureLocalStorage.getItem("savePayloadRequestParams")) {
          var parsedQMF = JSON.parse(
            secureLocalStorage.getItem("savePayloadRequestParams")?.formContent
          );

          setQMF(parsedQMF);
          setShowLoader(false);
        } else {
          if (secureLocalStorage.getItem("updatedQMF")) {
            let parsedQMF =
              secureLocalStorage.getItem("updatedQMF")?.formContent;
            setQMF(parsedQMF);
            setShowLoader(false);
          } else {
            let parsedQMF = JSON.parse(response.formContent);
            setQMF(parsedQMF);
            setShowLoader(false);
          }
        }
      })
      .catch((error) => {
        error?.data?.message && message.error(error?.data?.message);
      });
  };

  const performAudit = (prop) => {
    if (
      secureLocalStorage.getItem("savePayloadRequestParams")?.Stage !==
      "Audit Completed"
    ) {
      let action;
      let createdBy = null;
      let updatedBy = null;
      let createdAt = "";
      let updatedAt = "";
      let auditMethod;
      let savePayloadRequestParams;
      let auditStage = "Audit Pending";
      let auditStatus = "ACTIVE";
      let auditLink = "";
      const auditedQuestionCount = secureLocalStorage.getItem(
        "auditedQuestionCount"
      )
        ? secureLocalStorage.getItem("auditedQuestionCount")
        : Object.keys(formValue).length;
      const score = secureLocalStorage.getItem("auditScore")
        ? secureLocalStorage.getItem("auditScore")
        : qualityScore;

      const totalQuestionCount = totalCount;

      if (id && secureLocalStorage.getItem("savePayloadRequestParams")) {
        //On refresh after marking anything from the checklist of the audit or visiting to audit from listing

        savePayloadRequestParams = secureLocalStorage.getItem(
          "savePayloadRequestParams"
        );
        auditMethod = "PUT";
        createdAt = secureLocalStorage.getItem(
          "savePayloadRequestParams"
        )?.createdAt;

        updatedAt = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        updatedBy = lIUId;
        action = `/audits/${
          secureLocalStorage.getItem("savedAuditId") !== null &&
          (secureLocalStorage.getItem("auditFormCall") === null ||
            secureLocalStorage.getItem("auditFormCall") ===
              "performAuditUpdate") &&
          secureLocalStorage.getItem("fromComponent") !== "audits"
            ? secureLocalStorage.getItem("savedAuditId")
            : id
        } `;
      } else {
        if (secureLocalStorage.getItem("auditFormCall") === "performAudit") {
          //Create - Visiting here from Perform an audit form

          {
            /*begin:: Update active audit count in Audit From Collection*/
          }
          APICaller(
            secureLocalStorage.getItem("role"),
            secureLocalStorage.getItem("accessToken"),
            `/auditForms/updateActiveAudits/${
              secureLocalStorage.getItem("savePayloadRequestParams")
                ? secureLocalStorage.getItem("savePayloadRequestParams")
                    ?.auditForm
                : location.pathname.split("/")[2]
            }`,
            "PUT",
            {
              role: role,
              process: secureLocalStorage.getItem("savePayloadRequestParams")
                ? secureLocalStorage.getItem("savePayloadRequestParams")
                    ?.processId
                : prevData?.processId,
              activeAudits: 1,
            }
          )
            .then((response) => {})
            .catch((error) => {});
          {
            /*end:: Update active audit count in Audit From Collection*/
          }
          auditMethod = "POST";
          createdAt = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          createdBy = lIUId;
          action = `/audits`;
          secureLocalStorage.setItem("auditFormCall", "performAuditUpdate"); //To stop further PUT audit method
          secureLocalStorage.removeItem("timeStampsDetails");
          secureLocalStorage.setItem("timeStampsDetails", {
            CreatedAt: createdAt,
          });
          secureLocalStorage.setItem("weightageFlag", 0);
        } else {
          //Update - Without refreshing and marking anything from the checklist of the audit
          savePayloadRequestParams = secureLocalStorage.getItem(
            "savePayloadRequestParams"
          );
          auditMethod = "PUT";
          createdAt =
            secureLocalStorage.getItem("timeStampsDetails")?.CreatedAt;
          updatedAt = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          updatedBy = lIUId;
          action = `/audits/${
            secureLocalStorage.getItem("savedAuditId") !== null &&
            secureLocalStorage.getItem("auditFormCall") === "performAuditUpdate"
              ? secureLocalStorage.getItem("savedAuditId")
              : id
          }`;

          secureLocalStorage.setItem("timeStampsDetails", {
            CreatedAt: createdAt,
            UpdatedAt: updatedAt,
          });
        }
      }

      if (prop === "finish") {
        auditStage = "Audit Completed";
        auditStatus = "CLOSED";
        const baseUrl = window.location.origin;
        auditLink =
          baseUrl +
          `/audits/${
            secureLocalStorage.getItem("savedAuditId") !== null
              ? secureLocalStorage.getItem("savedAuditId")
              : id
          }`;
        secureLocalStorage.setItem("auditFormSubmit", true);
      }

      const fatalNotFatalInfo = {
        fatal: fatalCount,
        notFatal: notFatalCount,
      };

      const auditedScoreInfo = {};

      auditedScoreInfo.auditedQuestionCount = crrScore ? crrScore : 0;
      auditedScoreInfo.auditedMaxQuestionCount =
        scoreQuestionCount && scoreQuestionCount;
      auditedScoreInfo.score = score && score.toString();
      auditedScoreInfo.actualScore = score && score.toString();
      auditedScoreInfo.maxScore = score && score.toString();
      auditedScoreInfo.totalQuestionCount =
        totalQuestionCount && totalQuestionCount;

      APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        action,
        auditMethod,
        {
          role: role,
          client: savePayloadRequestParams
            ? savePayloadRequestParams?.clientId
            : prevData?.clientId,
          department: savePayloadRequestParams
            ? savePayloadRequestParams?.departmentId
            : prevData?.departmentId,
          process: savePayloadRequestParams
            ? savePayloadRequestParams?.processId
            : prevData?.processId,
          auditForm: savePayloadRequestParams
            ? savePayloadRequestParams?.auditForm
            : location.pathname.split("/")[2],
          transaction_id: savePayloadRequestParams
            ? savePayloadRequestParams?.transactionId
            : prevData?.transactionId,
          transactionDate: savePayloadRequestParams
            ? savePayloadRequestParams?.transactionDate
            : prevData?.transactionDte,
          transactionDuplicateReason: savePayloadRequestParams
            ? savePayloadRequestParams?.transactionDuplicateReason
            : prevData?.transactionDuplicateReason,
          auditBackDateReason: savePayloadRequestParams
            ? savePayloadRequestParams?.auditBackDateReason
            : prevData?.auditBackDateReason,
          agent: savePayloadRequestParams
            ? savePayloadRequestParams?.agentId
            : prevData?.agentId,
          auditor: lIUId,
          stage: auditStage,
          status: auditStatus,
          auditedQuestionCount: auditedQuestionCount,
          totalQuestionCount: totalQuestionCount,
          auditedScoreInfo:
            auditedScoreInfo && JSON.stringify(auditedScoreInfo),
          fatalNotFatalInfo:
            fatalNotFatalInfo && JSON.stringify(fatalNotFatalInfo),
          score: score && score.toString(),
          createdBy: createdBy,
          updatedBy: updatedBy,
          createdAt: createdAt,
          updatedAt: updatedAt,
          formContent: QMF,
          auditLink: auditLink,
          hasWeightage: auditFormHasWeightage,
          insuredName: savePayloadRequestParams
            ? savePayloadRequestParams?.insuredName
            : prevData?.insuredName,
          transactionAmount: savePayloadRequestParams
            ? savePayloadRequestParams?.transactionAmount
            : prevData?.transactionAmount,
          invoiceCount: savePayloadRequestParams
            ? savePayloadRequestParams?.invoiceCount
            : prevData?.invoiceCount,
        }
      )
        .then((response) => {
          var parsedQMF = JSON.parse(response.formContent);
          let auditForm = response.auditForm;
          secureLocalStorage.setItem("updatedQMF", {
            id: auditForm,
            formContent: parsedQMF,
          });
          secureLocalStorage.setItem("savedAuditId", response?._id);

          if (secureLocalStorage.getItem("auditedFormSubmit") === true) {
            navigate("/audits");
          }
        })
        .catch((error) => {
          error?.data?.message && message.error(error?.data?.message);
        });
    }
  };

  const getValue = (value, id, isQualityMetric, weightage, serial_no) => {
    if (
      (secureLocalStorage.getItem("role") === "admin" ||
        secureLocalStorage.getItem("role") === "auditor" ||
        secureLocalStorage.getItem("role") === "qc") &&
      ((secureLocalStorage.getItem("auditFormCall") !== "performAudit" &&
        (secureLocalStorage.getItem("savePayloadRequestParams")?.aditorID ===
          secureLocalStorage.getItem("lIUId") ||
          prevData?.auditorId === secureLocalStorage.getItem("lIUId"))) ||
        (secureLocalStorage.getItem("auditFormCall") === "performAudit" &&
          prevData?.auditorId === secureLocalStorage.getItem("lIUId")))
    ) {
      /*begin::Set array in the way user audited the parameters of the Audit Form*/
      if (value === "Yes" || value === "No" || value === "Not Applicable") {
        if (secureLocalStorage.getItem("parameterSelectedList")) {
          let parameterSelectedList = secureLocalStorage.getItem(
            "parameterSelectedList"
          );

          if (!parameterSelectedList.includes(id)) {
            parameterSelectedList.unshift(id);
          } else {
            parameterSelectedList = parameterSelectedList.filter(
              (x) => x !== id
            );
            parameterSelectedList.unshift(id);
          }
          secureLocalStorage.setItem(
            "parameterSelectedList",
            parameterSelectedList
          );
        } else {
          secureLocalStorage.setItem("parameterSelectedList", [id]);
        }
      } else {
        setFormSavingFieldType("others");
      }
      /*end::Set array in the way user audited the parameters of the Audit Form*/

      var questions = QMF?.elements?.questions;
      let totalWeightage = 0; //Variable to store overall weightage of audit form having parameter's weightage
      let aQCount = 1;
      let aQSCount = 1;
      let countQA = []; //Variable to store all user audited parameter's value
      let isCriticalParameterHasNo = false;

      for (let i = 0; i < questions?.length; i++) {
        if (questions[i]?.qkey === id) {
          questions[i].selectedValue = value; //This is setting value in new property selectedValue
        }

        //Setting variable flag if it is critical parameter
        if (
          questions[i]?.meta?.required === true &&
          questions[i].selectedValue === "No"
        ) {
          isCriticalParameterHasNo = true;
        }
        if (questions[i].meta.weightage) {
          totalWeightage = totalWeightage + questions[i].meta.weightage;
          setAuditFormHasWeightage("TRUE");
        }
        if (questions[i].input_type === "radio-group") {
          switch (questions[i].selectedValue) {
            case "Yes":
              secureLocalStorage.setItem(
                "auditedQuestionScoreCount",
                aQCount++
              );
              countQA.push("Yes");
              break;
            case "No":
              countQA.push("No");
              break;
            case "Not Applicable":
              countQA.push("NotApplicable");
              if (secureLocalStorage.getItem("auditedQuestionScoreCount")) {
                if (
                  secureLocalStorage.getItem("auditedQuestionScoreCount") ===
                    1 &&
                  value === "Not Applicable" &&
                  auditFormHasWeightage === "TRUE"
                ) {
                  secureLocalStorage.setItem("auditedQuestionScoreCount", "0");
                }
              } else {
                secureLocalStorage.setItem("auditedQuestionScoreCount", "0");
              }
              break;

            default:
              break;
          }
        }

        if (questions[i].selectedValue && questions[i].selectedValue !== "") {
          secureLocalStorage.setItem("auditedQuestionCount", aQSCount++); //Setting for showing audited count for all questions of audit
        }
      }

      let fatalErrorCount = {};
      let nonFatalErrorCount = {};
      for (let i = 0; i < questions?.length; i++) {
        const key = questions[i]?.qkey;

        if (
          questions[i]?.meta?.critical === true &&
          questions[i]?.selectedValue === "No"
        ) {
          fatalErrorCount[key] = "No";
        }
        if (
          questions[i]?.meta?.critical === false &&
          questions[i]?.selectedValue === "No"
        ) {
          nonFatalErrorCount[key] = "No";
        }
      }

      setFatalCount(fatalErrorCount && Object.keys(fatalErrorCount).length);
      setNotFatalCount(
        nonFatalErrorCount && Object.keys(nonFatalErrorCount).length
      );
      let countMap = {};
      countQA.forEach(function (i) {
        countMap[i] = (countMap[i] || 0) + 1;
      });
      if (countMap.NotApplicable) {
        setCrrScore(countQA && countQA.length - countMap.NotApplicable);
        setScoreQuestionCount(maxScore - countMap.NotApplicable);
      } else {
        setCrrScore(countQA && countQA.length);
        setScoreQuestionCount(maxScore);
      }
      setIsCritical(isCriticalParameterHasNo);

      if (auditFormHasWeightage === "FALSE" && countMap.No) {
        if (countMap.Yes) {
          setCrrScore(countMap && countMap.Yes);
        } else {
          setCrrScore(0);
        }
      }
      secureLocalStorage.setItem("countMap", countMap);

      if (value === "Yes" || value === "No" || value === "Not Applicable") {
        secureLocalStorage.setItem("clickedLink", "onChange");
      } else {
        secureLocalStorage.setItem("clickedLink", "textOnChangeRequest");
      }

      if (
        secureLocalStorage.getItem("savePayloadRequestParams")?.Stage !==
        "Audit Completed"
      ) {
        SetFormValue({
          ...formValue,
          [id]: [
            value,
            isQualityMetric,
            weightage,
            totalWeightage,
            "",
            isCriticalParameterHasNo,
          ],
        });
      } else {
        if (
          currentDateTimeStamp < dateAfterAuditedDateTimeStamp &&
          id === "correction-user" &&
          secureLocalStorage.getItem("savePayloadRequestParams")?.Stage ===
            "Audit Completed" &&
          location?.state?.formMethd === "update" &&
          secureLocalStorage.getItem("role") === "auditor"
        ) {
          SetFormValue({
            ...formValue,
            [id]: [
              value,
              isQualityMetric,
              weightage,
              totalWeightage,
              isCriticalParameterHasNo,
            ],
          });
          let updatedAt = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          let updatedBy = lIUId;

          let action = `/audits/updateFormContent/${
            location?.pathname?.split("/")?.[2]
          }`;

          APICaller(
            secureLocalStorage.getItem("role"),
            secureLocalStorage.getItem("accessToken"),
            action,
            "PUT",
            {
              role: role,
              updatedBy: updatedBy,
              updatedAt: updatedAt,
              formContent: QMF,
            }
          )
            .then((response) => {})
            .catch((error) => {
              error?.data?.message && message.error(error?.data?.message);
            });
        }
      }

      if (auditFormAction === "") {
        setAuditFormAction("create");
        if (qualityScore !== 0) {
          performAudit();
        }
      } else {
        setAuditFormAction("update");
      }
    }
  };

  /*begin::Submit Audit button functionality*/
  const onFinish = () => {
    setAuditFormAction("update");
    performAudit("finish");
    message.success("Submit success!");
    secureLocalStorage.setItem("auditedFormSubmit", true);
  };

  const onFinishFailed = () => {
    (Number(qualityScore) < 100 &&
      tCountForAlertMessage ===
        secureLocalStorage.getItem("auditedQuestionCount")) ||
    (Number(qualityScore) === 100 && crrScore === scoreQuestionCount)
      ? message.error("Submission failed!")
      : message.error(
          "Submission failed because the score is less than 100, and a mandatory question with an asterisk (*) is still unanswered!"
        );
  };
  /*end::Submit Audit button functionality*/

  useEffect(() => {
    /*Begin::Auto Save functionality onChange*/
    if (auditFormAction === "create") {
      if (formSavingFieldType === "") {
        setQualityScore(
          getScore(
            formValue,
            scoreQuestionCount,
            auditFormHasWeightage,
            "",
            isCritical
          )
        );
      }
      if (secureLocalStorage.getItem("auditScore") !== 0) {
        performAudit();
      }
    }
    if (auditFormAction === "update") {
      if (formSavingFieldType === "") {
        setQualityScore(
          getScore(
            formValue,
            scoreQuestionCount,
            auditFormHasWeightage,
            "",
            isCritical
          )
        );
      }
      if (
        secureLocalStorage.getItem("auditScore") !== qualityScore ||
        secureLocalStorage.getItem("auditScore") === qualityScore
      ) {
        performAudit();
      }
    }
    /*End::Auto Save functionality onChange*/
  }, [formValue]);

  const handleAuditsBack = () => {
    const allKeys = Object.keys(localStorage);
    // Loop through the keys and remove items that are not the specific item
    let exceptItems = [
      "@secure.s.lIU",
      "@secure.s.lIUId",
      "@secure.s.role",
      "@secure.s.accessToken",
      "@secure.s.accessTokenExpiry",
      "@secure.s.refreshToken",
      "@secure.s.refreshTokenExpiry",
    ];

    allKeys.forEach((key) => {
      if (!exceptItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    setGlobalState(false);
    navigate("/audits");
  };

  const currentDateTimeStamp = new Date(new Date()).getTime();
  const dateAfterAuditedDateTimeStamp =
    secureLocalStorage.getItem("timeStampsDetails") &&
    getDateAfterWeek(
      moment(secureLocalStorage.getItem("timeStampsDetails")?.CreatedAt).format(
        "YYYY-MM-DD"
      ),
      "timeStamp"
    );

  useEffect(() => {
    if (Number(qualityScore) < 100) {
      // Select all elements with the class 'text123'
      const elementsWithText123 = document.querySelectorAll(
        ".auditFormField .ant-form-item-control-input .ant-form-item-control-input-content"
      );

      // Loop through the selected elements and add an asterisk to each
      elementsWithText123.forEach((element) => {
        const specificChild = element.querySelector(".ant-radio-group");
        const text123Child = element.querySelector(".text123");

        if (!specificChild) {
          // Check if the next element is already an asterisk element
          const nextElement = text123Child?.nextElementSibling;
          if (
            nextElement &&
            nextElement.classList.contains("lessScoreMandatoryFields")
          ) {
            return; // Return early if the asterisk span already exists
          }

          // Create a span element with the asterisk symbol
          const asteriskSpan = document.createElement("span");
          asteriskSpan.innerText = " *";
          asteriskSpan.style.color = "red";
          asteriskSpan.classList.add("lessScoreMandatoryFields"); // Remove the = sign

          text123Child?.parentNode.insertBefore(
            asteriskSpan,
            text123Child?.nextSibling
          );
        }
      });
    }
    if (Number(qualityScore) === 100) {
      // Select all spans with the class 'lessScoreMandatoryFields'
      const spansToRemove = document.querySelectorAll(
        ".lessScoreMandatoryFields"
      );

      // Loop through the selected spans and remove them
      spansToRemove.forEach((span) => {
        span.remove();
      });
    }
  }, [qualityScore]);

  const [tCountForAlertMessage, setTCountForAlertMessage] = useState("");

  const divStructure = secureLocalStorage.getItem("savePayloadRequestParams")
    ?.Stage !== "" &&
    Number(qualityScore) < 100 && (
      <span className="feedback-content">
        {((secureLocalStorage.getItem("role") === "tl" &&
          (addAgentFeedback === 1 || agentFeedback || supervisorFeedback)) ||
          (secureLocalStorage.getItem("role") === "agent" &&
            (addAgentFeedback === 0 || agentFeedback || supervisorFeedback) &&
            agentFeedback != "Accepted")) && (
          <>
            <strong>Feedback | </strong>{" "}
          </>
        )}

        {secureLocalStorage.getItem("role") === "admin" ||
        secureLocalStorage.getItem("role") === "tl" ||
        secureLocalStorage.getItem("role") === "atl"
          ? addAgentFeedback === 0 && agentFeedback
            ? "Agent : " + agentFeedback
            : "Agent : Awaited"
          : ""}
        {secureLocalStorage.getItem("role") === "admin" && ", "}
        {(secureLocalStorage.getItem("role") === "admin" ||
          secureLocalStorage.getItem("role") === "agent") &&
        agentFeedback &&
        agentFeedback != "Accepted"
          ? addSupervisorFeedback === 0 && supervisorFeedback
            ? "Supervisor : " + supervisorFeedback
            : "Supervisor : Awaited"
          : ""}
      </span>
    );

  const headLine = QMF?.elements?.meta?.headline;
  const singlePageContent = { divStructure, headLine };

  return (
    <div className="single-page">
      {showLoader === true && <div className="customLoader">Loading...</div>}
      <BeforeTableHeader
        singlePage="deletedAuditView"
        singlePageContent={singlePageContent}
        list=""
        href=""
      />

      <div className="container1 auditViewC">
        <div className="scrollbar" id="style-4">
          <span className="text11">Audit</span>
          <Divider />
          <Form
            id="myform"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            scrollToFirstError
          >
            {QMF && ParseElementsIntoFormNew(QMF, getValue)}
          </Form>
        </div>
        {/*begin::To show Audit Details in right sidebar*/}
        <div className="audit-detailscontainer p-3">
          <DeletedAuditDetails
            auditFormInput={auditFormInput}
            fatalNotFatalInfo={{
              fatal: fatalCount,
              notFatal: notFatalCount,
            }}
            timeStampsDetails={secureLocalStorage.getItem("timeStampsDetails")}
            qualityScore={qualityScore}
            auditorProfileImage={auditorProfileImage}
            agentProfileImage={agentProfileImage}
          />
        </div>
        {/*end::To show Audit Details in right sidebar*/}
      </div>
      {/*Audited and Feedback Section*/}
      {(location?.state?.route === "/deleted-audits" ||
        location?.pathname.split("/")?.[1] === "deleted-audits") && (
        <div className="auditedVInfo p-3 bottom-users d-flex justify-content-between">
          <div className="scoreInfo bottom-users">
            <img
              src={
                auditorProfileImage?.profilePicUrl
                  ? auditorProfileImage?.profilePicUrl
                  : process.env.REACT_APP_BACKEND + "/images/dummy.jpg"
              }
              alt={auditorProfileImage?.name}
            />
            <span className="text11 bottom-users">
              Audited&nbsp;&nbsp;{crrScore}/{scoreQuestionCount}
              &nbsp;&nbsp;Score: {qualityScore}%
            </span>
          </div>

          <div className="feedback-send-container">
            {secureLocalStorage.getItem("role") !== "agent" &&
              secureLocalStorage.getItem("auditedFormSubmit") !== true &&
              ((secureLocalStorage.getItem("auditFormCall") !==
                "performAudit" &&
                (secureLocalStorage.getItem("savePayloadRequestParams")
                  ?.aditorID === secureLocalStorage.getItem("lIUId") ||
                  prevData?.auditorId ===
                    secureLocalStorage.getItem("lIUId"))) ||
                (secureLocalStorage.getItem("auditFormCall") ===
                  "performAudit" &&
                  prevData?.auditorId ===
                    secureLocalStorage.getItem("lIUId"))) && (
                <Button
                  className="audit-detail-buttons"
                  onClick={() => {
                    let tCount = totalCount;
                    if (dateCount) {
                      tCount = totalCount - dateCount;
                      setTCountForAlertMessage(tCount);
                    }

                    (Number(qualityScore) < 100 &&
                      tCount ===
                        secureLocalStorage.getItem("auditedQuestionCount")) ||
                    (Number(qualityScore) === 100 &&
                      crrScore === scoreQuestionCount)
                      ? onFinish()
                      : onFinishFailed();
                  }}
                >
                  Submit Audit
                </Button>
              )}

            {(secureLocalStorage.getItem("role") === "tl" ||
              secureLocalStorage.getItem("role") === "atl" ||
              secureLocalStorage.getItem("role") === "agent") && (
              <>
                {secureLocalStorage.getItem("role") === "tl" ||
                secureLocalStorage.getItem("role") === "atl" ? (
                  <>
                    {addAgentFeedback === 0 &&
                      addSupervisorFeedback === 1 &&
                      agentFeedback != "Accepted" && (
                        <>
                          <Button
                            className="audit-detail-buttons dispute-btn"
                            onClick={() => handleOk("sd")}
                          >
                            Dispute
                          </Button>
                          <Button
                            className="audit-detail-buttons acceptFeedbackbtn"
                            onClick={() => handleOk("saf")}
                          >
                            Accept Feedback
                          </Button>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {showDiv === true ? (
                      <>
                        <div className="popup-div reviewAuditPopup">
                          <p>Feedback</p>
                          {value === "comment" && (
                            <>
                              <textarea
                                className={`text__area ${
                                  auditReviewFormError?.message ? "error" : ""
                                }`}
                                placeholder="Add comment here"
                                onChange={(e) => {
                                  if (e.target.value !== "") {
                                    setAuditReviewFormError("");
                                  } else {
                                    setAuditReviewFormError((state) => ({
                                      ...state,
                                      message: "Review field is required",
                                    }));
                                  }
                                  setAuditCommentMessage(e.target.value);
                                }}
                                style={{
                                  marginBottom: "15px",
                                  width: "100%",
                                  backgroundColor: "#F0F0F0",
                                }}
                                rows={5}
                                width={"full"}
                              ></textarea>
                              {auditReviewFormError?.message && (
                                <span className="error">
                                  {auditReviewFormError?.message}
                                </span>
                              )}
                            </>
                          )}
                          <div style={{ display: "flex" }}>
                            <div>
                              <Radio.Group onChange={onChange} value={value}>
                                <Radio
                                  style={{ marginBottom: "15px" }}
                                  value={"Accepted"}
                                >
                                  Accept
                                </Radio>
                                <br />
                                {(secureLocalStorage.getItem("role") === "tl" ||
                                  secureLocalStorage.getItem("role") ===
                                    "atl") && (
                                  <>
                                    <Radio
                                      style={{ marginBottom: "15px" }}
                                      value={"Disputed"}
                                    >
                                      Raise Dispute
                                    </Radio>
                                    <br />
                                  </>
                                )}
                                {secureLocalStorage.getItem("role") ===
                                  "agent" && (
                                  <>
                                    <Radio
                                      style={{ marginBottom: "15px" }}
                                      value={"comment"}
                                    >
                                      Requires discussion
                                    </Radio>
                                    <br />
                                  </>
                                )}
                              </Radio.Group>
                            </div>
                          </div>
                          <Divider />
                          <div>
                            {submitReview === true && (
                              <Button
                                key="submit"
                                type="primary"
                                style={{
                                  backgroundColor: "#16AD52",
                                  float: "right",
                                  borderRadius: "5px",
                                  marginRight: "10px",
                                }}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
                              </Button>
                            )}
                            {submitReview === false && (
                              <>
                                <Button
                                  key="submit"
                                  type="primary"
                                  onClick={() => handleOk("")}
                                  style={{
                                    backgroundColor: "#16AD52",
                                    float: "right",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <img
                                    src={"../check.png"}
                                    style={{ width: "15px" }}
                                    alt="logo"
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;Submit
                                </Button>

                                <Button
                                  key="back"
                                  onClick={handleCancel}
                                  style={{
                                    backgroundColor: "#E0E0E0",
                                    float: "right",
                                    borderRadius: "5px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={"../cancel.png"}
                                    style={{ width: "12px" }}
                                    alt="logo"
                                  />
                                  &nbsp;&nbsp;&nbsp; Cancel
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}

                    {addAgentFeedback === 1 && Number(qualityScore) < 100 && (
                      <Button
                        className="audit-detail-buttons acceptFeedbackbtn"
                        onClick={onClickReview}
                      >
                        Feedback&nbsp;{" "}
                        <img
                          src={"../upward.png"}
                          style={{ width: "15px" }}
                          alt="logo"
                        />
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default DeletedAuditView;

import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { message } from "antd";
const mainDomain = process.env.REACT_APP_BACKEND;
const token = secureLocalStorage.getItem("accessToken");
const role = secureLocalStorage.getItem("role");

const APICaller = async (
  role,
  token,
  endpoint,
  method,
  body,
  responseType = "json"
) => {
  try {
    const response = await axios({
      url: `${mainDomain}${endpoint}`,
      method: method || "GET",
      data: body,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        role: role,
      },
      responseType: responseType,
    });
    let data = response.data;
    return data;
  } catch (error) {
    var errorMsg = "";
    // Check if the error is due to a network issue (NestJS server not reachable)
    if (!error.response) {
      if (token && role != "") {
        errorMsg =
          "Unable to connect to the server. Please sign-in later after signout, when server gets started.";
      } else {
        errorMsg =
          "The server is currently offline. Please try again later, when server gets started.";
      }

      message.error(errorMsg, 2);
    } else {
      // Display Ant Design message for API error
      if (error.response.data) {
        errorMsg = error.response.data.message || "An error occurred";
        message.error(errorMsg, 2);
      } else {
        message.error("An error occurred");
      }
    }
    throw error;
  }
};
export default APICaller;

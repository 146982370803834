import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../utils/GlobalStateContext";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";
import moment from "moment";

const Header = ({ nameLink }) => {
  /*begin::To fetch the global search results*/
  const location = useLocation();
  const [list, setList] = useState([]),
    [searchData, setSearchData] = useState([]),
    [allTransactionIdsArr, setAllTransactionIdsArr] = useState([]),
    [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const { instance } = useMsal();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const navigateSignIn = () => {
    navigate("/");
  };

  const handleSignOut = () => {
    instance.logoutPopup();
    setGlobalState(false);
    secureLocalStorage.clear();
    navigateSignIn();
  };

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [listItems, setListItems] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const handleOnChange = async (e) => {
    let hasSearchedValue = "";
    if (e.target.value) {
      hasSearchedValue = "yes";
      secureLocalStorage.setItem("hasSearchedValue", hasSearchedValue);
      secureLocalStorage.setItem("searchKeyword", e.target.value);
    }
    setShowLoader(true);
    const searchIn = location.pathname.replace("/", "");
    const searchKeyword = e.target.value ? e.target.value : "";
    const callActionFrom = "searchBar";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const allStates = {
      searchData: searchData,
      setSearchData: setSearchData,
      selectedRowsArray: selectedRowsArray,
      setSelectedRowsArray: setSelectedRowsArray,
      allTransactionIdsArr: allTransactionIdsArr,
      setAllTransactionIdsArr: setAllTransactionIdsArr,
      listItems: listItems,
      setListItems: setListItems,
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination,
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const response = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
    if (response) {
      setShowLoader(false);
    }
  };
  const handleOnChange1 = async (e, page = 1, pageSize = 50) => {
    const allAudits = await APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/search",
      "POST",
      {
        page,
        pageSize,
        role: secureLocalStorage.getItem("role"),
        lIUId: secureLocalStorage.getItem("lIUId"),
        searchIn: location.pathname.replace("/", ""),
        searchKeyword: e.target.value,
      }
    );

    const { response, totalCount } = allAudits;
    const temp = [];
    switch (location.pathname.replace("/", "")) {
      case "auditForms": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            form_name: dataList?.formName,
            active_audits: dataList?.activeAudits,
            created_at: moment(dataList?.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "auditForms",
          recordCount: totalCount,
        });
        secureLocalStorage.setItem("searchData", temp);
        break;
      }
      case "audits": {
        const elemnt = document.querySelectorAll(
          ".all-table-holder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container table .ant-table-tbody tr"
        );
        // Loop through the selected elements and add an asterisk to each
        elemnt.forEach((element) => {
          const isSelected = element.classList.contains(
            "ant-table-row-selected"
          );
          if (isSelected) {
            element
              .querySelector(".ant-table-cell.ant-table-selection-column label")
              .click();
          }
        });
        const elmnt = document.querySelector(
          ".all-table-holder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container table thead tr th div.ant-table-selection label.ant-checkbox-wrapper"
        );
        elmnt
          .querySelector("span.ant-checkbox")
          .classList.remove("ant-checkbox-indeterminate");
        if (response) {
          response.map((dataList, index) => {
            temp.push({
              key: dataList?._id,
              id: dataList?._id,
              transaction_id: dataList?.transaction_id,
              auditor: dataList?.auditor,
              agent: dataList?.agent,
              supervisor: dataList?.agentReportingSupervisor?.name
                ? dataList?.agentReportingSupervisor?.name
                : dataList?.agentAtl?.name,
              createdAt: dataList?.createdAt,
              transaction_date: dataList?.transactionDate,
              auditBackDateReason: dataList?.auditBackDateReason,
              form: dataList?.auditForm?.formName,
              formId: dataList?.auditForm?._id,
              stage: dataList?.stage,
              score:
                dataList?.auditedScoreInfo &&
                JSON.parse(dataList?.auditedScoreInfo)?.score,
              active: dataList?.status,
              comments: dataList?.comment,
            });
          });
          setSearchData(temp);
        }

        const inputValue = e.target.value;

        // Check if the last character is a space
        if (inputValue.charAt(inputValue.length - 1) !== " ") {
          setGlobalState({
            ...globalState,
            exportAuditReportJson: "[{}]",
            selectedRowKeysChildInfo: [],
            searchResult: "empty",
            inputValue: inputValue,
          });
          setSelectedRowsArray([]);
        }

        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "audits",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            current: page,
            pageSize: pageSize,
            total: totalCount,
          },
        });

        break;
      }
      case "escalations": {
        const elemntEscalation = document.querySelectorAll(
          ".all-table-holder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container table .ant-table-tbody tr"
        );
        // Loop through the selected elements and add an asterisk to each
        elemntEscalation.forEach((element) => {
          const isSelected = element.classList.contains(
            "ant-table-row-selected"
          );
          if (isSelected) {
            element
              .querySelector(".ant-table-cell.ant-table-selection-column label")
              .click();
          }
        });
        const elmntEscalation = document.querySelector(
          ".all-table-holder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container table thead tr th div.ant-table-selection label.ant-checkbox-wrapper"
        );
        elmntEscalation
          .querySelector("span.ant-checkbox")
          .classList.remove("ant-checkbox-indeterminate");

        response.map((dataList, index) => {
          temp.push({
            key: index + 1,
            id: dataList?._id,
            agent: dataList?.agent,
            reportingTl: dataList?.reportingTl,
            errorImpact: dataList?.errorImpact,
            transaction: dataList?.transactionId,
            transactionDuplicateReason: dataList?.transactionDuplicateReason,
            department: dataList?.department?.name,
            client: dataList?.client?.name,
            stage: dataList?.stage,
            status: dataList?.status,
            errorRaiseDate:
              dataList?.errorRaiseDate &&
              moment(dataList?.errorRaiseDate).format("DD/MM/YYYY H:mm A"),
            escalation_id: "122234",
          });
        });
        setSearchData(temp);

        const inptValue = e.target.value;

        // Check if the last character is a space
        if (inptValue.charAt(inptValue.length - 1) !== " ") {
          setGlobalState({
            ...globalState,
            exportAuditReportJson: "[{}]",
            selectedRowKeysChildInfo: [],
            searchResult: "empty",
            inputValue: inptValue,
          });
          setSelectedRowsArray([]);
        }
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "escalations",
          recordCount: totalCount,
        });
        break;
      }
      case "users": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            empID: dataList?.empID,
            name: dataList?.name,
            email: dataList?.email,
            role: dataList?.role,
            client: dataList?.client?.name,
            created_at:
              dataList?.createdAt &&
              moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);

        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "users",
          recordCount: totalCount,
        });
        break;
      }
      case "roles": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            name: dataList?.name,
            created_at:
              dataList?.createdAt &&
              moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "roles",
          recordCount: totalCount,
        });
        break;
      }
      case "clients": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            name: dataList?.name,
            created_at:
              dataList?.createdAt &&
              moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "clients",
          recordCount: totalCount,
        });
        break;
      }
      case "processes": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            name: dataList?.name,
            department: dataList?.department?.name,
            created_at:
              dataList?.createdAt &&
              moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "processes",
          recordCount: totalCount,
        });
        break;
      }
      case "departments": {
        response.map((dataList, index) => {
          temp.push({
            key: dataList?._id,
            id: index + 1,
            name: dataList?.name,
            created_at:
              dataList?.createdAt &&
              moment(dataList?.createdAt).format("DD-MM-YY HH:mm A"),
          });
        });
        setSearchData(temp);
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          recordCountFor: "departments",
          recordCount: totalCount,
        });
        break;
      }
      default: {
        break;
      }
    }
    setShowLoader(false);
    setPagination({
      ...pagination,
      current: page,
      pageSize,
      total: 2,
    });
  };

  useEffect(() => {
    if (searchData !== null) {
      setList(searchData);
      setGlobalState({
        ...globalState,
        list: secureLocalStorage.getItem("searchData"),
      });
    }
  }, [searchData]);

  const handleHamburger = () => {
    // Get elements by class name
    let sideNavElements = document.getElementsByClassName("sidenav");
    let rightSideElements = document.getElementsByClassName("right-side");

    // Check if there are elements with the specified class
    if (sideNavElements.length > 0) {
      // Access the first element in the collection and toggle the class
      sideNavElements[0].classList.toggle("open");
    }

    if (rightSideElements.length > 0) {
      // Access the first element in the collection and toggle the class
      rightSideElements[0].classList.toggle("right-side-open");
    }
  };

  return (
    <div className="top-header">
      {/*TOP HEAder Start**/}
      {((location.pathname.split("/")[1] !== "auditForms" ||
        location.pathname.split("/")[1] !== "audits" ||
        location.pathname.split("/")[1] === "escalations") &&
        !location.pathname.split("/")[2]) ||
      ((location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "roles" ||
        location.pathname.split("/")[1] === "forms") &&
        location.pathname.split("/")[2]) ? (
        <>
          <div className="hamburger" onClick={handleHamburger}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="logo-area">
        {globalState?.logo && (
          <img
            src={process.env.REACT_APP_BACKEND + `/images/${globalState?.logo}`}
            className="logo-img"
            alt="logo"
          />
        )}
        <span className="qcc_logo">{globalState?.logoTitle}</span>
      </div>
      {((location.pathname.split("/")[1] !== "auditForms" ||
        location.pathname.split("/")[1] !== "audits" ||
        location.pathname.split("/")[1] !== "escalations") &&
        !location.pathname.split("/")[2] &&
        location.pathname.split("/")[1] !== "forms" &&
        location.pathname.split("/")[1] !== "feedback-requests") ||
      ((location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "roles") &&
        location.pathname.split("/")[2] &&
        location.pathname.split("/")[1] !== "forms" &&
        location.pathname.split("/")[1] !== "feedback-requests") ? (
        <div className="align-items-center d-flex name_search">
          <div className="namelinkclass">{nameLink}</div>
          <div className="search-area ml-2">
            {location?.pathname?.split("/")[2] !== "add" &&
            location.pathname.replace("/", "") !== "settings" ? (
              <>
                <div className="searchInput">
                  {location?.pathname?.split("/")[2] !== "add" ? (
                    <>
                      <img
                        src="/search.png"
                        style={{ width: "17px" }}
                        alt="logo"
                        className="searchSymb"
                      />{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  <input
                    type="text"
                    className="inputS"
                    placeholder={
                      "Search " + location.pathname.replace("/", "") + "....."
                    }
                    onChange={handleOnChange}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <>
          {location.pathname.split("/")[1] === "forms" ||
          (location.pathname.split("/")[1] === "feedback-requests" &&
            location.pathname.split("/")[2] !== "preview") ? (
            <span className="namelinkclass name_search">
              {location?.pathname.split("/")?.[1] &&
                location?.pathname.split("/")?.[1].charAt(0).toUpperCase() +
                  location?.pathname.split("/")?.[1].slice(1)}
            </span>
          ) : (
            <span className="namelinkclass name_search">
              {location?.pathname.split("/")?.[1] &&
                location?.pathname.split("/")?.[1].charAt(0).toUpperCase() +
                  location?.pathname.split("/")?.[1].slice(1)}
              /#{location.pathname.split("/")[2]}
            </span>
          )}
        </>
      )}

      <button className="main-sign-out" onClick={handleSignOut}>
        Sign Out
      </button>

      {/*TOP HEAder Start**/}
    </div>
  );
};

export default Header;

import React from "react";
import { Modal, Form, Input } from "antd";

const AuditBackDateReasonForm = (props) => {
  const { open, setVisible, onCreate } = props;
  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <Modal
      open={open}
      title=""
      okText="Submit"
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleCreate}
      width={450}
      className="reasonModal"
    >
      <Form form={form} layout="">
        <Form.Item
          label={
            <>
              <span className="auditBackDateReason-label">
                Provide reason for Backdate entry
              </span>
            </>
          }
          name="auditBackDateReason"
          rules={[{ required: true, message: "Please input the reason!" }]}
          labelAlign="left"
          className="auditBackDateReason"
        >
          <Input.TextArea rows={2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AuditBackDateReasonForm;

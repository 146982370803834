import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import { useLocation } from "react-router-dom";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";

import FeedbackAlreadyGiven from "../../layouts/FeedbackAlreadyGiven";
import "../../../App.css";
import SurveyComponent from "./SurveyComponent.jsx";
import Unauthorized from "../../layouts/Unauthorized.jsx";
import SurveyExpired from "./surveyExpired.jsx";

const Survey = ({ children }) => {
  const [showLoader, setShowLoader] = useState(true);
  const [feedbackForm, setFeedbackForm] = useState({});
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();

  /*begin::getFeedbackRequestsList will fetch list of users*/
  const id = location.pathname.split("/")[2];
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const getFormDetail = () => {
    const allKeys = Object.keys(localStorage);
    // Loop through the keys and remove items that are not the specific item
    let exceptItems = [
      "@secure.s.lIU",
      "@secure.s.lIUId",
      "@secure.s.role",
      "@secure.s.accessToken",
      "@secure.s.accessTokenExpiry",
      "@secure.s.refreshToken",
      "@secure.s.refreshTokenExpiry",
    ];
    let removeItems = [
      "@secure.s.isSubMenuActive",
      "@secure.j.auditFormInput",
      "@secure.s.auditFormCall",
      "@secure.n.totalAuditedCount",
      "@secure.j.parameterSelectedList",
      "@secure.n.auditedQuestionScoreCount",
      "@secure.n.auditedQuestionCount",
      "@secure.j.countMap",
      "@secure.s.clickedLink",
      "@secure.s.auditScore",
      "@secure.j.timeStampsDetails",
      "@secure.n.weightageFlag",
      "@secure.j.updatedQMF",
      "@secure.s.savedAuditId",
      "@secure.j.savePayloadRequestParams",
    ];
    allKeys.forEach((key) => {
      if (removeItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      `/feedback-requests/survey/${id}`,
      "GET"
    )
      .then((response) => {
        if (response?.status === true) {
          setFeedbackStatus(true);
          setShowLoader(false);
        } else if (buttonClicked === true) {
          let parsedFeedbackForm = JSON.parse(response.formContent);
          let questions = parsedFeedbackForm?.elements;
          setFeedbackForm(questions);
          setShowLoader(false);
        } else {
          if (response?.status === false) {
            setShowLoader(false);
            setFeedbackStatus("noSurvey");
          } else if (response?.status === "expired") {
            setShowLoader(false);
            setFeedbackStatus("surveyExpired");
          } else {
            var parsedFeedbackForm = JSON.parse(response.formContent);
            let questions = parsedFeedbackForm?.elements;
            setFeedbackForm(questions);
            setShowLoader(false);
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        error?.data?.message && message.error(error?.data?.message);
      });
  };
  useEffect(() => {
    getFormDetail();
    document.title = "Customer Satisfaction Survey"; // Set your dynamic title here
  }, []);

  /*end::getFeedbackRequestsList will fetch list of users*/

  const headLine = feedbackForm?.elements?.meta?.headline;

  const singlePageContent = { headLine };
  const handleClick = () => {
    setButtonClicked(true);
    getFormDetail();
  };
  // Define your survey JSON
  const surveyJSON = feedbackForm && feedbackForm;

  useEffect(() => {
    getFormDetail();
  }, []);

  return (
    <div className="single-page surveyCom-container">
      {showLoader === true && <div className="customLoader">Loading...</div>}
      {secureLocalStorage.getItem("role") !== null &&
      secureLocalStorage.getItem("role") !== "" ? (
        <>
          <Unauthorized />
        </>
      ) : feedbackStatus === true ? (
        <>
          <FeedbackAlreadyGiven />
        </>
      ) : feedbackStatus === "surveyExpired" ? (
        <>
          <SurveyExpired />
        </>
      ) : buttonClicked === true ? (
        <>
          <div className="surveyCom-outer-container">
            <div>
              <span>{headLine}</span>
              <Form
                id="myform"
                form={form}
                layout="vertical"
                autoComplete="off"
                scrollToFirstError
              >
                {surveyJSON && (
                  <SurveyComponent surveyJSON={surveyJSON} id={id} />
                )}
              </Form>
            </div>
          </div>
        </>
      ) : feedbackStatus === "noSurvey" ? (
        <>
          <div className="underMaintainance-container">
            <h1 className="text1">Survey doesn't exist!</h1>
          </div>
        </>
      ) : (
        <>
          <section className="survey feedback">
            <div className="main-area">
              <div className="feedbtitle">
                <h3>Feedback</h3>
              </div>
              <div className="content-holder">
                <h4>Customer Satisfaction Survey</h4>
                <p>
                  We always want to improve. Could you help us out for a few
                  minutes?
                </p>
                <button
                  className="feedbackbutton"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Let's Start
                </button>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
export default Survey;

import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import "../users/addUser.css";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import { useLocation } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import HandleSearch from "../../../utils/CommonSearchComponent";

const { Option } = Select;

const AddAuditForm = ({ children }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  const [errors, setErrors] = useState([]),
    [processes, setProcesses] = useState([]);

  /*begin::Add new audit form*/
  const [form] = Form.useForm();
  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }
  const onFinish = (values) => {
    const jsonString = values?.formContent;

    if (!isValidJSON(jsonString)) {
      message.error("Form content is not valid json");
    } else {
      APICaller(
        secureLocalStorage.getItem("role"),
        secureLocalStorage.getItem("accessToken"),
        "/auditForms",
        "POST",
        {
          source: "form",
          formName: values?.formName,
          formContent: values?.formContent,
          process: values?.process,
          activeAudits: 0,
          hasWeightage: false,
          createdAt: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        }
      )
        .then((response) => {
          setErrors([]);
          message.success("Audit Form created successfully");
          // Reset form fields
          form.resetFields();
          getAuditFormList();
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            setErrors(error.data.message);
          } else {
            message.error("An error occurred while creating the Audit Form");
          }
        });
    }
  };

  /*begin::getAuditFormList will fetch list of audits*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const getAuditFormList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const { current, pageSize } = pagination;

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 100 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const allRecs = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };
  /*end::Add new audit form*/

  /*begin::getProcesses will fetch the list of Processes*/
  const getProcesses = async () => {
    await APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/processes/fetchWithoutPagination",
      "GET"
    )
      .then((response) => {
        const temp = [];
        response?.map((process, index) => {
          temp.push({ label: process?.name, value: process?._id });
        });

        setProcesses(temp);
      })
      .catch((error) => {});
  };

  /*end::getProcesses will fetch the list of Processes*/
  useEffect(() => {
    getProcesses();
  }, []);

  return (
    <>
      <div className="all-content addUserCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Form Name"
                  name="formName"
                  rules={[
                    { required: true, message: "Please enter the form name!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Process"
                  name="process"
                  rules={[
                    { required: true, message: "Please select a process!" },
                  ]}
                >
                  <Select options={processes} className=""></Select>
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Form Content"
                  name="formContent"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the form content!",
                    },
                  ]}
                  validateStatus={
                    errors && errors["formContent"] ? "error" : undefined
                  }
                  help={
                    errors && errors["formContent"]
                      ? errors["formContent"]
                      : undefined
                  }
                >
                  <TextArea className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>
              <div className="d-flex justify-content-center mt-4 w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddAuditForm;

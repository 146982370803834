/*Import files dynamically*/
import React, { useState, Suspense, useContext, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import "./login.css";
import secureLocalStorage from "react-secure-storage";

import { GlobalStateContext } from "../../utils/GlobalStateContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { storeAccessToken, storeRefreshToken } from "../../utils/tokenStorage";

const EmailVerification = React.lazy(() => import("../EmailVerification"));

const Login = () => {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { instance, accounts } = useMsal();
  const handleSignIn = async () => {
    secureLocalStorage.clear();
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });
      // Handle successful login here
      setGlobalState((prevGlobalState) => ({
        ...prevGlobalState,
        isLoggedIn: true,
      }));
      storeAccessToken(
        loginResponse.accessToken,
        loginResponse.expiresOn.getTime()
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [serverStatus, setServerStatus] = useState("Error");
  const checkServerStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND + "/checkServerStatus"
      );

      setServerStatus(response.data.status);
    } catch (error) {
      navigate("/underMaintenance");
      setServerStatus("Error");
    }
  };

  useEffect(() => {
    checkServerStatus();
  }, []);

  return (
    <>
      {serverStatus !== "Error" && (
        <div className="login_box">
          <div className="login-wrap">
            <div className="login-page-logo">
              <img src="/logo.svg" style={{ width: "200px" }} alt="logo" />
              <span> &nbsp;</span>
              <span> &nbsp;</span>
              <span className="separator"></span>
              <span className="qcc_logo">&nbsp;QCC</span>
            </div>
            <span
              className="sign_in"
              style={{ textAlign: "center" }}
              onClick={() => {
                handleSignIn();
              }}
            >
              Sign in with Microsoft
            </span>
            {globalState.isLoggedIn && (
              <Suspense fallback={<div>Loading...</div>}>
                <EmailVerification />
              </Suspense>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

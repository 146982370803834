import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Select, message } from "antd";
import "./forms.css";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const SendForm = ({ children }) => {
  const [forms, setForms] = useState([]),
    [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showLoader, setShowLoader] = useState(false);
  const onFinish = (values) => {
    setShowLoader(true);
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/sendForms",
      "POST",
      {
        formName: values?.formName,
        recipients: values?.recipient,
        status: "OPEN",
        createdBy: secureLocalStorage.getItem("lIUId"),
      }
    )
      .then((response) => {
        setErrors([]);
        setShowLoader(false);
        message.success("Form created successfully");
        // Reset form fields
        form.resetFields();
        navigate("/forms");
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the form");
        }
      });
  };

  {
    /*begin::getForms will fetch the list of Forms*/
  }
  const getForms = () => {
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/fetchWithoutPagination",
      "POST",
      { route: "forms", action: "findAll" }
    )
      .then((response) => {
        const temp = [];
        response?.map((form, index) => {
          temp.push({
            label: form?.formDisplayName,
            value: form?._id,
          });
        });
        setForms(temp);
      })
      .catch((error) => {});
  };
  {
    /*end::getForms will fetch the list of Forms*/
  }

  {
    /*For Recipients dropdown values in state*/
  }
  const childrenList = [];
  const [selectedValues, setSelectedValues] = useState([]);
  const handleChange = (value) => {
    setSelectedValues(value);
  };

  useEffect(() => {
    getForms();
  }, []);

  return (
    <>
      <div className="all-content addUserCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Form Name"
                  name="formName"
                  rules={[
                    { required: true, message: "Please select a form name!" },
                  ]}
                >
                  <Select className="" options={forms}></Select>
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Recipients"
                  name="recipient"
                  placeholder="Please input recipient's email address"
                  rules={[
                    {
                      required: true,
                      message: "Please input recipient's email address.",
                    },
                  ]}
                  validateStatus={
                    errors && errors["email"] ? "error" : undefined
                  }
                  help={errors && errors["email"] ? errors["email"] : undefined}
                >
                  <Select
                    mode="tags"
                    size={"large"}
                    placeholder="Enter Email ID's"
                    value={selectedValues}
                    onChange={handleChange}
                  >
                    {childrenList}
                  </Select>
                </Form.Item>
              </Col>

              <div className="d-flex justify-content-center mt-4 w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default SendForm;

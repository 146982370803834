import secureLocalStorage from "react-secure-storage";

// tokenStorage.js
const storeAccessToken = (accessToken, expiryTime) => {
  // Store the access token and its expiration time in local storage
  secureLocalStorage.setItem("accessToken", accessToken);
  secureLocalStorage.setItem("accessTokenExpiry", expiryTime);
};

const storeRefreshToken = (refreshToken, expiryTime) => {
  // Store the refresh token and its expiration time in local storage
  secureLocalStorage.setItem("refreshToken", refreshToken);
  secureLocalStorage.setItem("refreshTokenExpiry", expiryTime);
};

export { storeAccessToken, storeRefreshToken };

import React, { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import APICaller from "../utils/APICaller";
import { useMsal } from "@azure/msal-react";
import { message } from "antd";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const token = secureLocalStorage.getItem("accessToken")
    ? secureLocalStorage.getItem("accessToken")
    : "";
  const currentAccount = instance.getActiveAccount();
  useEffect(() => {
    token && postToken();
  }, [token || currentAccount?.username]);

  const navigateHome = (role) => {
    role === "agent" || role === "tl" || role === "atl"
      ? navigate("/audits")
      : navigate("/auditForms");
  };

  // Declaring API for Getting User Reward
  const postToken = () => {
    APICaller("", token, "/validateToken", "POST", {
      auth: token,
    })
      .then((response) => {
        if (response.statusCode === 200) {
          response.statusCode === 200 && validateEmail();
        } else {
          // Handle non-2xx status codes
          // You might want to log or handle the error accordingly
          console.error("Non-2xx status code:", response);
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          message.error(error.data.message);
        }
      });
  };

  const validateEmail = () => {
    APICaller("", token, "/emailVerification", "POST", {
      email: currentAccount?.username,
    })
      .then((response) => {
        secureLocalStorage.setItem("lIUId", response?.respnse?._id);
        secureLocalStorage.setItem("role", response?.respnse?.role);
        secureLocalStorage.setItem(
          "reportingSupervisor",
          response?.respnse?.reportingSupervisor
        );
        response.statusCode === 200 && navigateHome(response?.respnse?.role);
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          message.error(error.data.message);
        }
      });
  };
  return <></>;
};

export default EmailVerification;

import { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
/* import 'antd/dist/antd.min.css' */
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import secureLocalStorage from "react-secure-storage";
import EmailVerification from "./components/EmailVerification";
import AuditForms from "./components/qms/auditForms/auditForms";

//Components
import Login from "./components/login/login";
import Audits from "./components/qms/audits/audits";
import Escalations from "./components/qms/escalations/escalations";
import NewEscalation from "./components/qms/escalations/newEscalation";
import EscalationView from "./components/qms/escaltionView/escalationView";
import NotFound from "./components/layouts/NotFound";
import AuditFormView from "./components/qms/auditForms/auditFormView";
import Users from "./components/qms/users/users";
import Roles from "./components/qms/roles/roles";
import AddUser from "./components/qms/users/addUser";
import AddRole from "./components/qms/roles/addRole";
import UnderMaintenance from "./components/layouts/UnderMaintenance";
import Main from "./components/layouts/main/main";
import AuditView from "./components/qms/auditView/auditView";

import FeedbackRequests from "./components/voc/feedbackRequests/feedbackRequests";
import Departments from "./components/qms/departments/departments";
import Processes from "./components/qms/processes/processes";
import Clients from "./components/qms/clients/clients";
import AddAuditForm from "./components/qms/auditForms/addAuditForms";

import Settings from "./components/settings";
import ErrorBoundary from "./utils/ErrorBoundary";
import SendForms from "./components/voc/forms/forms";
import AddForm from "./components/voc/forms/addForm";
import Survey from "./components/voc/survey/survey";
import { useMsal } from "@azure/msal-react";
import { GlobalStateContext } from "./utils/GlobalStateContext";
import FeedbackRequestPreview from "./components/voc/feedbackRequests/feedbackRequestPreview";
import DeletedAudits from "./components/qms/deletedAudits";
import DeletedAuditView from "./components/qms/deletedAudits/deletedAuditView";

const UserContext = createContext();

function App({ msalInstance }) {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  useEffect(() => {
    if (
      (location.pathname.split("/")[1] === "auditForms" ||
        location.pathname.split("/")[1] === "audits" ||
        location.pathname.split("/")[1] === "escalations") &&
      location.pathname.split("/")[2]
    ) {
      // Get elements by class name
      let sideNavElements = document.getElementsByClassName("sidenav");
      let rightSideElements = document.getElementsByClassName("right-side");

      // Check if there are elements with the specified class
      if (sideNavElements.length > 0) {
        // Access the first element in the collection and toggle the class
        sideNavElements[0].classList.remove("open");
      }

      if (rightSideElements.length > 0) {
        // Access the first element in the collection and toggle the class
        rightSideElements[0].classList.remove("right-side-open");
      }
    }
  });

  // Define your components - Forbidden, AuditForms, Audits, AuditView, Users, AddUser, Roles, AddRole
  const userRole = secureLocalStorage.getItem("role");

  const componentMap = {
    "/auditForms": (
      <Main children={<AuditForms />} key_elem={"2"} nameLink="Audit Forms" />
    ),
    "/auditForms/:id": (
      <Main
        children={<AuditFormView />}
        key_elem={"3"}
        nameLink="AuditForm View"
      />
    ),
    "/auditForms/add": (
      <Main
        children={<AddAuditForm />}
        key_elem={"4"}
        nameLink="Add AuditForm"
      />
    ),
    "/audits": <Main children={<Audits />} key_elem={"5"} nameLink="Audits" />,

    "/deleted-audits": (
      <Main
        children={<DeletedAudits />}
        key_elem={"6"}
        nameLink="Deleted Audits"
      />
    ),
    "/deleted-audits/:id": (
      <Main
        children={<DeletedAuditView />}
        key_elem={"7"}
        nameLink="Deleted Audit View"
      />
    ),
    "/audits/:id": (
      <Main children={<AuditView />} key_elem={"7"} nameLink="Audit View" />
    ),
    "/users": <Main children={<Users />} key_elem={"8"} nameLink="Users" />,
    "/users/add": (
      <Main children={<AddUser />} key_elem={"9"} nameLink="Add User" />
    ),
    "/roles": <Main children={<Roles />} key_elem={"10"} nameLink="Roles" />,
    "/roles/add": (
      <Main children={<AddRole />} key_elem={"11"} nameLink="Add Role" />
    ),
    "/auditReports": () => <div>Audit Reports</div>, // Replace with your Audit Reports component <Main children={<></> } key_elem={'3'} nameLink='Audit Reports' />
    "/escalations": (
      <Main
        children={<Escalations children={<NewEscalation />} />}
        key_elem={"12"}
        nameLink="Escalations"
      />
    ),
    "/escalations/:id": (
      <Main
        children={<EscalationView />}
        key_elem={"13"}
        nameLink="Escalation View"
      />
    ),
    "/clients": (
      <Main children={<Clients />} key_elem={"14"} nameLink="Clients" />
    ),
    "/processes": (
      <Main children={<Processes />} key_elem={"15"} nameLink="Processes" />
    ),
    "/departments": (
      <Main children={<Departments />} key_elem={"16"} nameLink="Departments" />
    ),
    "/forms": (
      <Main children={<SendForms />} key_elem={"17"} nameLink="Send Forms" />
    ),
    "/forms/add": (
      <Main children={<AddForm />} key_elem={"18"} nameLink="Add Form" />
    ),
   
    "/feedback-requests/:type": (
      <Main
        children={<FeedbackRequests />}
        key_elem={"19"}
        nameLink="Feedback Requests"
      />
    ),
    "/feedback-requests/preview/:id": (
      <Main
        children={<FeedbackRequestPreview />}
        key_elem={"20"}
        nameLink="Feeback Request Preview"
      />
    ),
    "/settings": (
      <Main children={<Settings />} key_elem={"21"} nameLink="Settings" />
    ),
    noRoute: "/404",
    "/underMaintenance": <UnderMaintenance />,
  };

  const hasAccess = (role) => {
    if (window.performance) {
      const entries = performance.getEntriesByType("navigation");

      if (entries.length > 0 && entries[0].type === "reload") {
        if (secureLocalStorage.getItem("hasSearchedValue")) {
          secureLocalStorage.removeItem("hasSearchedValue");
        }
        if (secureLocalStorage.getItem("searchKeyword")) {
          secureLocalStorage.removeItem("searchKeyword");
        }
        if (
          (location.pathname.split("/")[1] !== "users" ||
            location.pathname.split("/")[1] !== "roles") &&
          location.pathname.split("/")[2]
        ) {
          // Get elements by class name
          let sideNavElements = document.getElementsByClassName("sidenav");
          let rightSideElements = document.getElementsByClassName("right-side");

          // Check if there are elements with the specified class
          if (sideNavElements.length > 0) {
            // Access the first element in the collection and toggle the class
            sideNavElements[0].classList.remove("open");
          }

          if (rightSideElements.length > 0) {
            // Access the first element in the collection and toggle the class
            rightSideElements[0].classList.remove("right-side-open");
          }
        }
      }
    }
    const roles = {
      admin: [
        "/auditForms/:id",
        "/auditForms",
        "/audits",
        "/audits/:id",
        "/deleted-audits",
        "/deleted-audits/:id",
        "/auditReports",
        "/escalations",
        "/escalations/:id",
        "/users",
        "/permissions",
        "/roles",
        "/clients",
        "/departments",
        "/processes",
        "/forms",
        "/forms/add",
        "/feedback-requests",
        "/feedback-requests/:type",
        "/settings",
        "/survey/:id",
        "/feedback-requests/preview/:id",
      ],
      auditor: [
        "/auditForms",
        "/auditForms/:id",
        "/audits",
        "/audits/:id",
        "/deleted-audits",
        "/deleted-audits/:id",
      ],
      qc: [
        "/auditForms",
        "/auditForms/:id",
        "/audits",
        "/audits/:id",
        "/deleted-audits",
        "/deleted-audits/:id",
        "/escalations",
        "/escalations/:id",
        "/forms",
        "/feedback-requests",
        "/feedback-requests/:type",
        "/survey/:id",
        "/feedback-requests/preview/:id",
      ],
      atl: ["/audits", "/audits/:id"],
      tl: ["/audits", "/audits/:id"],
      agent: ["/audits", "/audits/:id"],
    };
    return roles[role] || [];
  };
  //"userRoleuserRole",userRole);
  const accessibleRoutes = userRole
    ? hasAccess(userRole)
    : ["/underMaintenance"];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = secureLocalStorage.getItem("accessToken");

    const account = msalInstance.getActiveAccount(); // Get the active account

    if (location.pathname === "/" && accessToken && userRole) {
      //console.log("RwT", account, ", s=>", accessToken);
      const navLink =
        userRole === "tl" || userRole === "atl" || userRole === "agent"
          ? "/audits"
          : "/auditForms";
      navigate(navLink, { replace: true });
    } else if (
      (account === null && accessToken) ||
      (userRole === null && accessToken)
    ) {
      //console.log("AccNwT", account, ", s=>", accessToken);
      // Redirect to login page or prompt the user to sign in again
      instance.logoutPopup();
      setGlobalState(false);
      secureLocalStorage.clear();
      navigate("/");
    } else {
      if (userRole === null && location.pathname.split("/")[1] !== "survey") {
        navigate("/");
      }
      //console.log("NoCase", account, ", s=>", accessToken);
    }
    setLoading(false); // Set loading to false after processing
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Render loading indicator if still loading
  if (loading) {
    return <div></div>;
  }
  //console.log("accessibleRoutes:",accessibleRoutes);
  return (
    <ErrorBoundary>
      <UserContext.Provider
        value={{
          loggedIn: isLoggedin,
          loginSetter: () => {
            setIsLoggedin(!isLoggedin);
          },
        }}
      >
        <Routes>
          <Route
            index
            path="/"
            element={
              isLoggedin ? (
                <Main
                  children={<AuditForms />}
                  key_elem={"1"}
                  nameLink="Audit Forms"
                />
              ) : (
                <Login /> /*Login -> EmailVerification*/
              )
            }
          />
          <Route path="/emailVerification" element={<EmailVerification />} />
          <Route path="/underMaintenance" element={<UnderMaintenance />} />
          <Route path="/survey/:id" element={<Survey />} />
          {accessibleRoutes.map((route) => (
            <Route key={route} path={route} element={componentMap[route]} />
          ))}
          <Route
            path="*"
            element={<Navigate to={componentMap.noRoute} replace />}
          />{" "}
          {/* Handling default or unmatched routes */}
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </UserContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
export { UserContext };

import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Row, Col } from "antd";

import APICaller from "../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";

const Settings = ({ children }) => {
  const role = secureLocalStorage.getItem("role");
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Read image parameters
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        // Check for SVG file type
        const isSVG = file.type === "image/svg+xml";
        if (!isSVG) {
          setFileList([]);
          message.error("You can only upload SVG file!");
        } else {
          setFileList(img.src);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  /*begin::call to api for creating new role*/
  const onFinish = (values) => {
    if (!setFileList) {
      console.error("No file selected.");
      return;
    }

    const headers = {
      "Content-Type": "multipart/form-data", // Make sure to set the content type
    };
    APICaller(
      secureLocalStorage.getItem("role"),
      secureLocalStorage.getItem("accessToken"),
      "/settings",
      "POST",
      {
        title: values?.title,
        logo: fileList,
        createdBy: "65c9916f4a76e9c64a469269",
        uploadType: "other",
      },
      headers
    )
      .then((response) => {
        const imgUrl =
          process.env.REACT_APP_BACKEND + "/images/settings" + response;
        setErrors([]);
        message.success("Settings saved successfully");
        // Reset form fields
        form.resetFields();
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while saving the settings");
        }
      });
  };

  const [imageUrls, setImageUrls] = useState([]);

  return (
    <>
      <div className="all-content settingsContainer">
        <div className="all-table-holder">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={4}>
                <Form.Item
                  label="Logo Title"
                  name="title"
                  rules={[
                    { required: true, message: "Please enter the title!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Upload Logo"
                  name="logo"
                  rules={[
                    { required: true, message: "Please upload the logo!" },
                  ]}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="upload-list-block"
                  />
                </Form.Item>
              </Col>
              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default Settings;

import React from "react";
import "../../App.css";
const Unauthorized = () => {
  return (
    <div className="underMaintainance-container">
      <h1 className="text1">You are not authorized to access this link.</h1>      
    </div>
  );
};

export default Unauthorized;

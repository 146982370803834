// GlobalStateContext.js
import React, { createContext, useState } from 'react';

// Create the context object
const GlobalStateContext = createContext();

// Create a provider component that will wrap your app and provide the global state to its children
const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState(false); // Replace 'initialValue' with your desired initial state

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateProvider };
